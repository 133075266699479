// src/controllers/gallery_controller.js

import { Controller } from "@hotwired/stimulus"
import GLightbox from "glightbox"

export default class extends Controller {
  connect() {
    this.nav = document.createElement('div');
    this.nav.classList.add('gallery-counter');
    this.nav.dataset.indicator = '/';

    this.lightbox = GLightbox({
      selector: '.js-gallery-item-hook',
      touchNavigation: true,
      loop: true,
      zoomable: false,
      autoplayVideos: false,
      onOpen: () => {
        this.lightbox.modal.appendChild(this.nav);
      },
      afterSlideChange: (prev, next) => {
        this.nav.dataset.indicator = `${next.index + 1} / ${this.lightbox.elements.length}`;
      }
    });
  }

  disconnect() {
    if (this.lightbox) {
      this.lightbox.destroy();
    }
  }
}

