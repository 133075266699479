import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menuIcon', 'closeIcon']

  connect() {
    this.isCollapsed = true;
  }

  toggleIcon() {
    this.isCollapsed = !this.isCollapsed;

    if (this.isCollapsed) {
      this.menuIconTarget.classList.remove("d-none");
      this.closeIconTarget.classList.add("d-none");
    } else {
      this.menuIconTarget.classList.add("d-none");
      this.closeIconTarget.classList.remove("d-none");
    }
  }
}
