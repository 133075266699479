import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    breakpoint: Number
  }

  connect() {
    this.toggleDropdowns()
    window.addEventListener('resize', this.toggleDropdowns)
  }

  disconnect() {
    window.removeEventListener('resize', this.toggleDropdowns)
  }

  // Expand the dropdown menu if the window is smaller than the breakpoint
  toggleDropdowns = () => {
    const dropdowns = this.element.querySelectorAll('.nav-item.dropdown')
    dropdowns.forEach(dropdown => {
      const dropdownMenu = dropdown.querySelector('.dropdown-menu')
      if (window.innerWidth < this.breakpointValue) {
        dropdown.classList.add('show')
        dropdownMenu.classList.add('show')
      } else {
        dropdown.classList.remove('show')
        dropdownMenu.classList.remove('show')
      }
    })
  }
}
