import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "newComment"]

  newCommentTargetConnected(element) {
    // clear input on new comment
    this.inputTarget.value = '';

    // scroll to new comment
    const rect = element.getBoundingClientRect();
    const offsetTop = window.pageYOffset + rect.top - 16; // Adjust the offset as needed

    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  }
}
